<template>
    <div style="background: var(--v-component-base) !important">
        <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                    <template slot="prepend">
                        <el-button @click="dialog= true, item = {modalType: 'Add',userTeams: []}"><i class="el-icon-plus"></i> Add Team</el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-data-table :items="teams" :loading="loading" :headers="headers" style="cursor: pointer;">
            <template v-slot:[`item.action`]="{ item }">
                <v-btn class="mx-1" small icon @click="editItem(item)">
                    <v-icon color="edit">edit</v-icon>
                </v-btn>
                <v-btn class="mx-1" small icon @click="removeItem(item)">
                    <v-icon color="danger">remove_circle_outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <v-avatar size="18" class="mr-2" :style="{background: item.colour}"></v-avatar>
                <span>{{item.name}}</span>
            </template>
            <template v-slot:[`item.userTeams`]="{ item }">
                <v-chip>{{item.userTeams.length}}</v-chip>
            </template>
        </v-data-table>
    
        <!-- Add/Edit Role dialog -->
        <el-dialog v-loading="loading" :title="item.modalType + ' Team'" :visible.sync="dialog" :fullscreen="$vuetify.breakpoint.mobile" width="850px" :closed="() =>{item = {}, dialog = false}">
            <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveTeam">
                <el-form-item prop="name">
                    <el-input v-model="item.name" placeholder="Name" clearable>
                        <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>
                <el-form-item prop="name" label="Colour">
                                 <el-color-picker v-model="item.colour"></el-color-picker>
    
                </el-form-item>
                <el-form-item prop="members">
                    <v-row justify="center">
                        <v-col cols="12" class="text-center pb-0 mb-0">
                            <h3>Members</h3>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4" class="text-center pt-0 mt-0">
                            <v-autocomplete :items="filterOrgUsers" item-value="id" dense outlined rounded label="Add Member" item-text="user.firstname" v-model="selectedUser" return-object>
                                <template v-slot:item="data">
                                    <v-list-item @click="addUser(data.item)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px">
                                                {{data.item.user.firstname}} {{data.item.user.surname}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                {{data.item.position}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="userHeaders" :items="item.userTeams" item-key="name" class="elevation-1">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn small icon @click="removeUserItem(item)">
                                <v-icon class="mx-1" color="red">remove_circle_outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </el-form-item>
    
                <el-form-item style="text-align: center">
                    <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Submit':''}}</el-button>
                    <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Save':''}}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
    </template>
    
    <script>
    export default {
        props: ['orgId'],
        data: () => ({
            dialog: false,
            headers: [{
                    text: 'Action',
                    align: 'center',
                    sortable: false,
                    value: 'action'
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center',
                }, {
                    text: 'Members',
                    value: 'userTeams',
                    align: 'center',
                },
            ],
            userHeaders: [{
                    text: 'Action',
                    align: 'center',
                    sortable: false,
                    value: 'action'
                },
                {
                    text: 'Name',
                    value: 'organisationUser.user.firstname',
                    align: 'center',
                },
                {
                    text: 'Surname',
                    value: 'organisationUser.user.surname',
                    align: 'center',
                }, {
                    text: 'Position',
                    value: 'organisationUser.position',
                    align: 'center',
                },
            ],
            item: {},
            loading: false,
            orgUsers: [],
            search: '',
            selectedUser: {},
            teams: []
        }),
        computed: {
            filterOrgUsers() {
                let result = this.orgUsers
                if (this.item && this.item.id) {
                    result = result.filter(x => !this.item.userTeams.map(y => y.organisationUserId).includes(x.id))
                }
                return result
            }
        },
        created() {
            this.load()
        },
        methods: {
            async addUser(userItem) {
                this.selectedUser = userItem
                if (this.item.id) {
                    let user = await this.$API.createTeamUserAdmin({
                        teamId: this.item.id,
                        organisationUserId: this.selectedUser.id
                    })
                    user.organisationUser = this.selectedUser
                    this.item.userTeams.push(user)
                } else {
                    this.item.userTeams.push({
                        organisationUser: this.selectedUser
                    })
                }
                this.selectedUser = {}
            },
            editItem(item) {
                this.item = item
                this.item.modalType = 'Edit'
                this.dialog = true
            },
            async getOrganisationUsers() {
                this.orgUsers = await this.$API.getOrganisationUsersAdmin(this.orgId)
            },
            async getTeams() {
                this.loading = true
                this.teams = await this.$API.getTeamsAdmin(this.orgId)
                this.loading = false
            },
            async load() {
                this.getTeams()
                this.getOrganisationUsers()
            },
    
            removeItem(item) {
                this.$confirm('Are you sure you want to delete this team?', 'Confirm', {
                    center: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'plain'
                }).then(async () => {
                    await this.$API.updateTeamAdmin({
                        id: item.id,
                        isActive: false,
                        isDeleted: true
                    })
                    this.teams.splice(this.teams.indexOf(item), 1)
                    this.$message({
                        type: 'success',
                        message: 'Successfully deleted!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },
            removeUserItem(user) {
                this.$confirm(`Are you sure you want to remove ${user.organisationUser.user.firstname} ${user.organisationUser.user.surname} from ${this.item.name}?`, 'Confirm', {
                    center: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'plain'
                }).then(async () => {
                    await this.$API.updateTeamUserAdmin({
                        id: user.id,
                        isActive: false,
                        isDeleted: true
                    })
                    this.item.userTeams.splice(this.item.userTeams.indexOf(user), 1)
                    this.$message({
                        type: 'success',
                        message: 'Successfully deleted!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },
            async saveTeam() {
                this.loading = true;
                this.item.organisationId = this.orgId
                if (this.item.id) {
                    await this.$API.updateTeamAdmin(this.item)
    
                } else {
                    let result = await this.$API.createTeamAdmin(this.item)
                    let userTeams = this.item.userTeams.map(x => ({
                        teamId: result.id,
                        organisationUserId: x.organisationUser.id
                    }))
                    await this.$API.createTeamUserAdmin({
                        batch: userTeams
                    })
                    this.getTeams()
                }
                this.dialog = false
                this.loading = false;
                this.item = {}
            }
    
        }
    }
    </script>
    